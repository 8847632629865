<template>
  <Page v-if="project && Object.values(project).length && !loading">
    <div slot="actionbar">
        <div class="btn-group">
          <div class="btn-group">
            <button
              aria-expanded="false"
              data-toggle="dropdown"
              class="btn btn-default dropdown-toggle"
            >
              Meer <i class="caret" />
            </button>
            <ul class="dropdown-menu pull-right">
              <li v-if="!isArchived">
                <a @click="archiveProject">
                  <i class="fa fa-archive" /> Project archiveren
                </a>
              </li>
              <li>
                <a @click="deleteProject">
                  <i class="fa fa-warning" /> Project verwijderen
                </a>
              </li>
            </ul>
          </div>
        </div>
    </div>

    <span slot="title">{{ project.reference }}</span>
    <div class="tabs-container">
      <ul class="nav nav-tabs" ref="tabBar">
        <router-link v-for="(link, index) in projectNavLinks" :key="index" tag="li" :to="link.to" data-toggle="tab" aria-expanded="false" active-class="active">
          <a>{{ link.name }}</a>
        </router-link>
      </ul>
      <div class="tab-content">
        <div class="tab-pane active">
          <div class="panel-body">
            <transition name="fade" mode="out-in">
              <router-view />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </Page>
  <div v-else key="loading" class="tw-h-10 tw-text-center">
    <i
      class="fal fa-spinner-third fa-spin tw-text-3xl"
    />
  </div>
</template>

<script>
import Page from '@/components/iam/Page'
import { mapActions, mapGetters } from 'vuex'
import { successModal, questionModal, errorModal } from '@/modalMessages'
import { setProjectArchived, setProjectDeleted } from '@/services/projects'

export default {
  name: 'ProjectLayout',
  components: { Page },
  data () {
    return {
      loading: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    ...mapActions('properties', ['loadProject']),

    async init () {
      try {
        this.loading = true
        await this.loadProject(this.projectId)
        this.project.entity_type = 'project'
      } catch (error) {
        errorModal('Fout bij ophalen van projectinformatie, probeer het opnieuw.')
      } finally {
        this.loading = false
      }
    },
    async archiveProject () {
      try {
        const result = await questionModal('Het archiveren van dit project en de onderliggende entiteiten kan niet ongedaan gemaakt worden.')
        if (!result.value) return
        const response = await setProjectArchived(this.projectId)
        successModal('Het archiveren van dit project en de onderliggende entiteiten is geslaagd')
        await this.loadProject(this.projectId)
        return response
      } catch (error) {
        errorModal('Er is iets misgegaan bij het archiveren van dit project en de onderliggende entiteiten')
      }
    },
    async deleteProject () {
      try {
        const questionModalString = `Het verwijderen van dit project en de onderliggende entiteiten kan niet ongedaan gemaakt worden.<br><br>
                                    <strong>Projecten en panden met leads, biedingen, bestellingen, digitale ondertekeningen en HMODH\'s
                                     kunnen niet worden verwijderd en moeten worden gearchiveerd.</strong>`
        const result = await questionModal(questionModalString)
        if (!result.value) return
        const response = await setProjectDeleted(this.project.id)
        this.$router.push({ name: 'ProjectSearch' })
        return response
      } catch (error) {
        errorModal(`Het project en de onderliggende entiteiten kunnen niet worden verwijderd en moeten in de plaats daarvan worden gearchiveerd.<br><br><strong>${error.response?.data?.[0]}</strong>`)
      }
    }
  },
  computed: {
    ...mapGetters('properties', ['getProjectById']),

    isArchived () {
      return this.project?.status === 99
    },

    projectNavLinks () {
      return [
        {
          to: { name: 'ProjectDetails' },
          name: 'Algemeen'
        },
        {
          to: { name: 'ProjectInfo' },
          name: 'Project'
        },
        {
          to: { name: 'ProjectEntities' },
          name: 'Entiteiten'
        },
        {
          to: { name: 'ProjectMedia' },
          name: 'Media'
        },
        {
          to: { name: 'ProjectFiles' },
          name: 'Bestanden'
        },
        {
          to: { name: 'ProjectMarketing' },
          name: 'Marketing'
        },
        {
          to: { name: 'ProjectTexts' },
          name: 'Teksten'
        },
        {
          to: { name: 'ProjectCommunication' },
          name: 'Communicatie'
        },
        {
          to: { name: 'ProjectLeads' },
          name: 'Leads'
        },
        {
          to: { name: 'ProjectChecklist' },
          name: 'Checklist'
        }
      ]
    },
    projectId () {
      return this.$route.params.id
    },
    project () {
      return this.getProjectById(this.projectId)
    }
  }
}
</script>
